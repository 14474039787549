.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #e86f51;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(14px + 2vmin);
  font-weight: bold;
  color: white;
}

/*.fade-in {*/
/*  opacity: 50%;*/
/*  transition:opacity 0.8s;*/
/*  color: black;*/
/*}*/

a:hover {
  transition:color 0.8s;
  color: whitesmoke;
}

.App-link {
  color: darkslategray;
  display: inline-block;
  margin-bottom: 10px;
  font-weight: bold;
  text-decoration: none;
}

h1 {
  font-size: 80px;
  text-shadow: 2px 2px gray;
  margin-bottom: 10px;
}
